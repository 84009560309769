import React from "react";
import {useTranslation} from "react-i18next";
import {Section} from "../../../common/section";

export const Specifications = () => {
  const {t} = useTranslation();

  return (
    <Section>
      <h2>{t('products.common.specifications.heading')}</h2>
      <table>
        <tbody>
        <tr>
          <td>Voltage</td>
          <td>230V/12/24</td>
          <td>✓</td>
          <td rowSpan={3}>Auto Program<br/>
            <br/>
            Safe System<br/>
            <br/>
            Bluetooth<br/>
            <br/>
            Battery unit
          </td>
        </tr>
        <tr>
          <td>Capacity (w)</td>
          <td>130</td>
          <td>✓</td>
        </tr>
        <tr>
          <td>UV-C Radiation</td>
          <td>130</td>
          <td>✓</td>
        </tr>
        </tbody>
      </table>
    </Section>
  )
}
