import React from "react";
import {NormalLayout} from "../../../components/layouts/normal_layout";
import {Introduction} from "../../../components/products/skeptron/car/introduction";
import {Benefits} from "../../../components/products/skeptron/car/benefits";
import {Details} from "../../../components/products/skeptron/car/details";
import {Applications} from "../../../components/products/skeptron/car/applications";
import {Specifications} from "../../../components/products/skeptron/car/specifications";
import {SEO} from "../../../components/seo";

const Car: React.FC = () => {

  return (
    <NormalLayout type="product">
      <SEO title="Skeptron Car | UV Tech by Rendev" description="Skeptron Car | UV Tech by Rendev" />
      <Introduction />
      <Benefits />
      <Details />
      <Applications />
      <Specifications />
    </NormalLayout>
  )
}

export default Car;